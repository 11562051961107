h2.static-vas-prod-heading {
    font-size: 40px;
    font-weight: 800;
    margin-top: 60px;
    margin-bottom: 20px;
}

.staic-vas-products {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 20px;
    column-gap: 20px;
    max-width: 950px;
    margin: 0 auto;

    .static-vas-inner {
        .inner-vas-staic {
            ul {
                list-style-type: none;
            }
        }
    }
}

.inner-vas-staic{
    background-color: #fff;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    position: relative;
    height: 100%;
    padding-bottom: 100px;

    .vas_staic_head{
        padding: 15px 20px;
        border-bottom: 1px solid #D3D3D3;
        text-align: center;

        h3 {
            font-size: 28px;
            color: #007DBA;
            font-weight: 700;
            margin: 0;
        }
        b {
            font-size: 20px;
            font-weight: 500;
            color: #57585C;
            display: block;
        }
    }

    .vas_staic_body{
        padding: 15px 20px;
        
        .vas_staic_txt{
            font-size: 16px;
            color: #57585C;
            font-weight: 500;
            margin-bottom: 18px;
        }
        .vas_staic_list{
            list-style: none;
            font-size: 16px;
            color: #57585C;
            font-weight: 500;
            li{
                position: relative;
                margin-bottom: 10px;
                padding-left: 40px;
                &:before{
                    content: '\f00c';
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                    font-size: 14px;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    border-radius: 100%;
                    color: rgba(61, 174, 43);
                    background: rgba(61, 174, 43, 0.16);
                    text-align: center;

                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
    }

    .vas_staic_foot{
        padding: 25px 20px;
        margin: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: 1px solid #D3D3D3;

        .static-vas-button{
            a{
                font-size: 17px;
                font-weight: 600;
                display: inline-block;
                padding: 13px 24px;
                min-width: 150px;
                text-align: center;
                border-radius: 6px;
                &:hover{
                    color: #fff;
                }
            }
        }
    }
}