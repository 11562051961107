.modalRoot {
  .ant-modal-body {
    padding: 2px 17px !important;
  }
  .ant-modal-header {
    padding: 13px 24px !important;
    background: #007cba;
  
    .ant-modal-title {
      color: #fff;
    }
  }
}

#select-unit {
  .ant-select-selector {
    max-height: 32px;
    width: 189px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
  }
}

.select-unit-input{
  margin-left: 1px;
}

.close-icon{
  margin-left: 562px;
  position: relative;
  bottom: 38px;
  color: #fff;
}

.modal-button-container{
  margin-top: 42px ;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}

.remove-second-unit{
  color: #007cba;
  font-size: 16px;
}

.modal-submit-button{
  margin-left: 15px;
}

.modal-cancel-button{
    margin-left: 15px;
}

.ant-modal-close-x span {
  background-color: #1890ff;
}

.address-header {
  margin-top: -21px;
  display: flex;
  align-items: baseline;
}

.location-icon {
  color: #007cba;
}

.address-text {
  margin-left: 3px;
  font-size: 14px;
}

.edit-address-icon {
  padding-top: 10px;
  margin-left: 5px;
  color: #007cba;
}

.row-conatiner {
  align-items: center;
}

.address-label {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

