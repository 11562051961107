.internet-result-heading {
  padding: 15px 30px 30px 30px !important;
  margin-top: 15px;
  @media (max-width: 1023px) {
    margin-top: 13px;
  }
  margin-bottom: 2.3rem;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -24px -60px;
  border-radius: 8px;


 
  .internet-outer {
    &.adjust-padding {
      padding: 0px;

      @media (max-width: 768px) {
        padding: 15px;
      }
    }
  }
  h1 {
    color: #007DB0;
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 16px;
    line-height: 1.1;
    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
      
    }
  }
  .address-paragraph {
    margin-bottom: 27px;
    button {
      color: #0075a3;
      text-decoration: underline;
    }
    @media (max-width: 768px) {
      font-size: 16px;      
    }
  }
  p {
    color: #333;
    margin: 0px;
    font-size: 18px;

    span,
    i {
      color: #007dba;
      text-decoration: underline;
    }

    i {
      cursor: pointer;
    }
  }
  button {
    background-color: transparent;
    color: #0075a3;
    border: none;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    padding-left: 5px;
  }
}

.internet-business-result-heading {
  margin-top: 15px;
  @media (max-width: 1023px) {
    margin-top: 13px;
  }
  margin-bottom: 2.3rem;
  background-color: #fff;
  background-image: url('../images/checkout-winter-bg-flakes@2x.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -24px -60px;
  border-radius: 8px;
  padding: 30px;
 
  .internet-outer {
    &.adjust-padding {
      padding: 0px;

      @media (max-width: 768px) {
        padding: 15px;
      }
    }
  }
  h1 {
    color: #0075a3;
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 11px;
    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
      
    }
  }
  .address-paragraph {
    margin-bottom: 27px;
   
    @media (max-width: 768px) {
      font-size: 16px;
     
      
    }
  }
  p {
    color: #333;
    margin: 0px;
    font-size: 18px;

    span,
    i {
      color: #007dba;
      text-decoration: underline;
    }

    i {
      cursor: pointer;
    }
  }
  button {
    background-color: transparent;
    color: #0075a3;
    border: none;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    padding-left: 5px;
  }
  .fixed-wireless-main-section {
    display: flex;
    column-gap: 30px;
    @media (max-width:1024px) {
     row-gap: 30px;
     flex-wrap: wrap;
    }
  }
  .business-plans-header {
    background: #0075a3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 22px;
    .business-plans-header-full-info {
      width: 60%;
    }
    h4{
      color: #fff !important;
    }
    .business-speed-info-card .business-plans-label {
      color: #fff;
      font-size: 16px;
      text-align: left;
    }

    .product-plan-speed{
      background-color: #fff;
      padding: 4px 7px;
      border-radius: 2px;
      border-bottom: 2px solid; 
      p {
        font-weight: 700;
        color: #0075a3;
      }
    }
  }

  .business-plan-header-section {
    width: 50%;
    @media (max-width:1024px) {
      width: 100%;
    }
    h1 {
      line-height: 44px;
    }
    .address-paragraph-with-btn {
      margin-bottom: 0;
    }

    .address-paragraph-with-btn button {
      color: #0075a3;
      text-decoration: underline;
    }
  }

  .fixed-wireless-list-section {
    width: 50%;
    border: 10px solid #f2f2f2;
    border-radius: 4px;
    @media (max-width:1024px) {
      width: 100%;
    }
    @media (max-width: 540px) {
      display: none;
    }
    h2 {
      border-bottom: 2px solid #f2f2f2;
      padding: 11px 13px;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 0;
      font-weight: 600;
    }
    ul {
      list-style: none;
      margin-left: 0;
      padding: 15px 30px;
      font-size: 18px;
    }
    ul li:before {
      content: "\f058"; /* Unicode for fa-circle-check */
      font-family: "Font Awesome 6 Free"; /* Use FontAwesome font */
      font-weight: 900; /* Required for solid icons */
      padding-right: 10px;
      color: #0075a3
    }
  }

  .toggle-button-container {
    margin-top: 20px;
  }

  .all-single-plans {
    .plan-single-inner{
      .business-plans-header{
        .business-plans-header-full-info{
          .small-text {
            font-size: 14px;
          }
        }
      }
    }
  }

}

.internet-result-price-heading {
  width: 100%;
  background-color: #F68D1F;
  color: #333;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  text-align: center;
  p {
    font-weight: 600;
  }
}

.result-heading {
  padding: 30px 0px 2rem;

  > .result-heading{
      padding: 0;
  }

  h1 {
      color: #444444;
      font-size: 40px;
      font-weight: 900;
      margin: 0px;
  }

  p {
      color: #444444;
      margin: 0px;
      font-size: 18px;

      span,
      i {
          color: #007dba;
          text-decoration: underline;
      }

      i {
          cursor: pointer;
      }
  }
  button{
      background-color: transparent;
      color: #007dba;
      border: none;
      cursor: pointer;
      padding: 0;
      font-weight: 600;
    }
}
.toggle-button-container {
  text-align: left;
  button {
    display: flex;
    font-size: 18px;
    align-items: center;
    text-decoration: none;
  }
}
.filters-top {
  position: absolute;
  width: 100%;
  left: 0;

  .top-filter.without-bundle {
    column-gap: 2%;
    .ant-col-6 {
      max-width: 32%;
      flex: 0 0 32%;
    }
  }
  .top-filter {
    column-gap: 2.6%;
    .ant-col-6 {
      max-width: 23%;
    }
  }

  .single-filter {
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 0px;
    background-color: #fff;
    border: 2px solid #0075a3;

    h4 {
      color: #007dba;
      margin: 0px;
      font-size: 17px;
      font-weight: bold;
    }

    p {
      color: #444444;
      margin: 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .single-filter.active-filter {
    background-color: #007cb0;
    color: #fff;

    h4,
    p {
      color: #fff;
    }
  }
}

#root .tabfixed {
  position: fixed;
  width: 100%;
  top: 81px;
  left: 0px;
  z-index: 99;
  margin: 0px !important;
  background-color: #fff;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d6;
}

.plans-row {
  margin-top: 7rem;
  .plan-heading.mobile-heading {
    margin-top: 2rem;
  }

  .plan-heading {
    h3 {
      font-size: 23px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0px;
    }

    i {
      font-size: 14px;
      background-color: #f68d1f;
      color: #fff;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: 2px solid #cf700c;
      margin-right: 12px;
    }
  }
}

.all-single-plans {
  > .ant-row {
    row-gap: 26px;
    margin: 1.5rem -10px 0;
  }

  .plan-single {
    padding: 0 10px;
    width:100%;
  }

  .plan-single-inner {
    background-color: #fff;
    text-align: center;
    border: 2px solid #0075a3;
    border-radius: 4px;
    height: 100%;
    position: relative;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    .agent-call-button {
      border-top: 2px solid #0075a3;
      margin-top: auto;
      a {
        background-color: #e98012;
        padding: 12px 28px;
        border-bottom: 4px solid #f78d1f;
        color: #333333;
        i {
          transform: rotate(0deg);
        }
      }
      a:hover,
      a:active,
      a:focus {
        color: #333;
        background-color: #f78d1f;
        border-bottom-color: #e98012;
      }
    }
    .plans-header {
      background: #0075A3;
      display: flex;

      justify-content: space-between;
      align-items: center;
      padding: 18px 22px;
      .speed-card {
        padding: 7px;
        border-radius: 7px;
        background-color: white;
      }

      .speed-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      .speed {
        font-size: 24px;
        font-weight: bold;
      }

      .speed-label {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #509e2f;
        border-radius: 5px;
        background-color: #fff;
      }

      .speed-icons {
        display: flex;
        margin-top: 5px;
      }

      .icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ddd;
        margin-right: 5px;
      }

      .icon.filled {
        background-color: #509e2f; /* Adjust color for filled icons */
      }
    }
    .grey-button {
      padding: 7px 14px;
    }

    .grey-button[disabled] {
      padding: 7px 18px;
    }

    h4 {
      color: #fff;
      font-size: 23px;
      font-weight: 600;
      display: flex;
      width: 100%;
      text-align: left;
      flex-direction: column;
    }

    h5 {
      color: #333333;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .bundle-card-header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .disable-bundle-text {
      .ant-select-selection-item {
        color: #959393;
      }
    }

    .bundle-select {
      position: relative;
      margin-bottom: 5px !important;

      .ant-select-selection-item {
        text-align: center;
      }

      .ant-select-arrow {
        color: #3190ff;
        font-weight: bolder;
        font-size: 16px;
      }
    }

    ul.bundle-ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    ul.list-bundle {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-right: 4px;
      padding-left: 20px;

      li:after {
        display: none;
      }
    }

    .below-list {
      background-color: whitesmoke;
      margin: 5px;
      overflow: auto;
      height: 140px;

      .list-bundle {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 4px;
      }

      .learnmore-heading {
        text-decoration: underline;
        font-weight: bolder;
        font-size: 1rem;
        color: #007cba;
      }
    }

    li.icon-list {
      display: flex;
      gap: 8px;

      .bundle-point {
        -webkit-line-clamp: 2;
      }
    }

    li.product-name {
      gap: 0 !important;
    }

    ul {
      margin: 0;
      list-style-type: none;
      text-align: left;
      color: #333333;
      font-size: 15px;
      padding: 17px 0px 12px 36px;

      li {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          height: 6px;
          width: 6px;
          background-color: #007bb0;
          left: -15px;
          border-radius: 50%;
          top: 8px;
        }

        p {
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          color: #333;
          -webkit-box-orient: vertical;
          padding-right: 10px;
        }
      }
    }

    .bundle-cart {
      padding: 14px;
      bottom: 0;
      width: 100%;
    }

    .down-cart {
      padding: 14px;
      border-top: 2px solid #0075a3;
      bottom: 0;
      width: 100%;
      margin-top: auto;
      button {
        i {
          margin-left: 10px;
          font-size: 16px;
        }
      }

      span.total-single {
        color: #444444;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .learnmore {
      text-align: right;
    }

    .font-blue {
      color: #3190ff;
    }

    .font-20 {
      font-size: 20px !important;
    }

    .learnmore-total {
      span.learnmore {
        width: 100%;
        text-align: left;
        font-size: 15px;
        color: #007cb0;
        font-weight: 500;
        margin: 8px 0px 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: underline;
      }
    }

    .main-below-total {
      text-align: right;

      span.learnmore {
        width: 100%;
        text-align: left;
        font-size: 16px;
        color: #007cb0;
        font-weight: 500;
        margin: 8px 0px 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: underline;
      }

      .discount {
        color: #b3b5b8;
        font-size: 14px;
        position: relative;
        width: 100%;
        display: inline-block;

        &:after {
          position: absolute;
          content: "";
          height: 42px;
          background-color: #ff0000;
          width: 1px;
          -webkit-transform: rotate(71deg);
          -ms-transform: rotate(71deg);
          transform: rotate(71deg);
          top: -13px;
          left: auto;
          right: 25px;
        }
      }
    }
  }

  .plan-single-inner.Seeless .down-cart {
    position: inherit;
  }

  .plan-single .seemore {
    color: #007cb0;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0 15px;
    padding-left: 20px;
    text-align: left;
    text-decoration: underline;
    width: 100%;
    background-color: transparent;
    border: none;
  }

  .Seeless ul li p {
    display: block;
  }

  .plan-single.mostPop {
    position: relative;

    &:before {
      content: "Most Popular";
      position: absolute;
      color: #333333;
      top: -31px;
      left: auto;
      z-index: 9;
      right: 10px;
      background-color: #f68d1f;
      font-weight: bold;
      font-size: 16px;
      padding: 3px 10px;
      border-radius: 4px 4px 0px 0px;
    }

    .plan-single-inner {
      border-radius: 4px 0px 4px 4px;
    }

    .red-ribbon {
      width: 62px;
      position: absolute;
      top: -7px;
      z-index: 98;
      left: 0px;
    }
  }

  .plan-single.supBun {
    position: relative;

    &:before {
      content: "Super Bundle";
      position: absolute;
      color: #333333;
      top: -31px;
      left: auto;
      z-index: 9;
      right: 0px;
      background-color: #f68d1f;
      font-weight: bold;
      font-size: 16px;
      padding: 3px 10px;
      border-radius: 4px 4px 0px 0px;
    }

    .plan-single-inner {
      border-radius: 4px 0px 4px 4px;
    }
  }

  .wireless-prod {
    .down-cart {
      padding: 14px 10px;

      span.total-single {
        font-size: 13px;
      }

      a.blue-button-phone {
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;

        i {
          margin-right: 6px;
        }
      }
    }
  }
}

.all-single-plans.internet-promo {
  margin-top: 0px;
}

.gift-card-desc {
  margin: 2rem 2rem 0 2rem;

  p {
    font-size: 20px;
    text-align: center;
  }
}

.bundle-outer,
.internet-outer {
  padding: 0px 30px;
}

.bundle-outer {
  margin-bottom: 3rem;

  .plan-single-inner {
    min-height: 200px;
  }
}

.mobilephone-outer {
  margin-bottom: 3rem;

  .plan-single-inner {
    min-height: 210px;
  }

  .plan-heading {
    i {
      font-size: 16px;
    }
  }

  .all-single-plans .mobilephone-quantity {
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    button {
      padding: 0px;
      background-color: inherit;
      border: unset;
      color: #fff;
      cursor: pointer;

      i {
        margin: 0;
        font-size: 13px;
        height: 20px;
        width: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #007cba;
        border-radius: 50%;
      }
    }

    button[disabled] {
      i {
        background-color: #d9d9d6;
        cursor: not-allowed;
      }
    }

    .counter-main {
      margin: 0px 10px;
    }

    p {
      color: #777777;
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 600;
    }
  }

  .mobilepone-subtotal p {
    color: #777777;
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 600;
  }
}

.rightsidebar-cart {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 5px;
  border-radius: 8px;
  margin-top: 30px;
  @media (max-width:1023px) {
    margin-top: 47px;
  }

  ul {
    list-style-type: none;
  }

  .common-cart-section {
    padding: 10px 10px;

    span.edit-icon i {
      color: #007cba;
      cursor: pointer;
      margin: 0px 6px 0px 0px;
      font-size: 14px;
    }
  }

  .b-top {
    border-top: 1px solid #d3d3d3;
  }

  h3 {
    color: #444444;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;

    .fa-cart-shopping {
      color: #f68d1f;
      margin-right: 8px;
    }
  }

  span.prdouct-count {
    height: 20px;
    width: 20px;
    color: #444444;
    border-radius: 50%;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    margin-left: 5px;
  }

  .collected-product {
    list-style-type: none;
    margin-top: 10px;

    .dotted {
      display: none !important;
    }

    > li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .item-plan {
      position: relative;
      margin-top: 12px;

      > div {
        z-index: 9;
        background-color: #fff;
        padding: 0px 2px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        &:first-child {
          text-transform: capitalize;
        }
      }
    }

    .delete-product i {
      display: inline-block;
      color: #ff3c38;
      margin: 0;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .product-heading {
    font-weight: bold;
  }

  .product-collect-heading {
    p {
      color: #444444;
      font-size: 13px;
      font-weight: 800;
      line-height: 15.85px;
    }
  }
  .right-green {
    font-weight: 600;
  }

  .added-products {
    color: #444444;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.85px;
  }
  .cart-terms {
    font-size: 13px;
    line-height: 15.85px;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #d3d3d3;

    .term-title {
      font-weight: 800;
      cursor: pointer;
      position: relative;
      padding-left: 20px;
      .cart_terms_icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        color: #f08b20;
        border: 1px solid #f08b20;
        border-radius: 100%;
        position: absolute;
        left: 0;
      }
    }
  }
  .broadband-label-heading {
    font-size: 18px;
    padding-bottom: 15px;
    padding-top: 15px;
    cursor: pointer;
    .external-link-icon {
      padding-left: 10px;
      i {
        color: #007cba;
      }
    }
  }
  .terms-text {
    p {
      font-size: 13px;
      color: #57585c;
      font-weight: 600;
      margin-top: 8px;
    }
  }

  li span,
  .one-time-change span {
    font-weight: 600;
    margin: 0px 0px 4px;
    display: inline-block;
  }

  .Total-with-checkout {
    color: #444444;
    font-size: 14px;
    text-align: center;

    button {
      margin: 20px auto 10px;
    }

    .left-total,
    .right-total {
      width: 50%;
    }

    .today-monthly-l {
      margin-right: 5px;
    }

    .today-monthly-r {
      position: relative;
      margin-left: 22px;

      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 2px;
        background-color: black;
        top: 0;
        -webkit-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
        transform: rotate(20deg);
        left: -13px;
      }
    }
  }
}
.back-btn {
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
  padding: 15px 0;
  display: block;
  .back-icon {
    padding-right: 10px;
  }
  border: none;
  background-color: #f7f7f7;
}
.mobile-info-last {
  margin: 4%;
}

#root .mobile-info h3 {
  padding: 5px 0px 0px;
  margin-bottom: 0px;
  line-height: 13px;
}

.common-pannel {
  background-color: #fff;
  padding: 25px 20px;
  border-radius: 8px;
  h3 {
    font-size: 20px;
    font-weight: 800;
    line-height: 24.38px;
    color: #444444;
    margin-bottom: 10px;
  }
  ul {
    list-style-type: none;
    background-color: #fff;
    li {
      color: #57585c;
      font-size: 13px;
      font-weight: 500;
      line-height: 24px;
      position: relative;
      padding-left: 20px;
      margin-top: 16px;
      letter-spacing: 0.5px;

      &:before {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        background-color: #f58b21;
        left: 5px;
        border-radius: 50%;
        top: 7px;
      }
    }
  }
}

// footer fixed
.result-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #007dba;
  color: #fff;
  z-index: 99;
  -webkit-transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
}

.reult-cart {
  -webkit-transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  background-color: #ffffff;
  position: fixed;
  color: #2b2b2b;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1070;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  width: 22%;
  box-shadow: 6px -4px 20px 0px #ccc;
}

.cart-empty {
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
}

.fixed-cart-result {
  .blue-row {
    padding: 15px 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  .blue-button.outer-continue-btn {
    background: inherit;
    padding: 0;
    color: #fff;
    border: none;
    float: right;
    margin-right: 5px;

    i {
      margin-left: 6px;
      transition: 0.2s;
    }

    &:hover {
      i {
        transform: translateX(5px);
        transition: 0.2s;
      }
    }
  }

  .blue-button.outer-continue-btn[disabled] {
    color: #91cce9;

    &:hover {
      i {
        transform: translateX(0px);
      }
    }
  }

  .result-fixed-m {
    margin: 1.5rem 0px;
  }

  .angle-icon {
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
  }

  .fixed-on-scroll {
    position: unset;
    overflow-x: scroll;
    height: 100%;
    padding-bottom: 6rem;

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #c1c1c1;
    }
  }

  .top-fix-cart {
    color: #007dba;
    font-size: 30px;
    font-weight: bold;
    padding: 1rem 0px 2rem;

    i {
      font-size: 25px;
      cursor: pointer;
    }
  }

  .on-result-remove,
  .b-top.result-none {
    display: none;
  }
}

.result-unfixd {
  .blue-button.outer-continue-btn {
    color: #007dba;
  }

  .result-fixed {
    background-color: #fff;
    color: #007dba;
    -webkit-transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
    transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
    bottom: -69px;
  }

  .reult-cart {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

 
  .angle-icon {
    .fa-angle-up {
      display: none;
    }

    .fa-xmark {
      display: block;
      padding: 6px 0px;
    }
  }

  .charge-info {
    color: #444444;
  }
}

.angle-icon .fa-xmark {
  display: none;
}

.height-f {
  min-height: calc(100vh - 185px);
  -webkit-transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
}

i.fa-solid.fa-angle-down {
  font-weight: bold;
  font-size: 21px;
}

i.fa-solid.fa-angle-up {
  font-weight: bold;
  font-size: 21px;
}

i.fa-solid.fa-arrow-down {
  color: #007cb0;
}

i.fa-solid.fa-arrow-up {
  color: #007cb0;
}
.earthlink-perks-section {
  margin-bottom: 60px;
  .ant-row {
    margin: 0;
    padding: 0;
    @media (max-width: 767px) {
      row-gap: 15px !important;
    }
  }
  .card {
    border: 0px !important;
    border-radius: 8px;
    height: 100%;
    .ant-card-body {
      padding: 0px;

      .image-container {
        object-fit: cover;
        position: relative;
        .logo-images {
          position: absolute;
          bottom: 30px;
          left: 30px;
          display: flex;
          gap: 12px;
        }
        .card-image {
          width: 100%;
          position: relative;
          height: 250px;
          border-radius: 8px 8px 0px 0px;
          object-fit: cover;
          @media (max-width: 767px) {
            height: 200px;
          }
        }
      }

      .card-content {
        padding: 30px 0px 30px 30px;
        @media (max-width: 767px) {
          padding: 10px 15px 15px 15px;
        }

        h2 {
          font-size: 36px;
          color: #0075a3;
          margin-bottom: 11px;
          font-weight: 600;
          line-height: 3rem;
          @media (max-width: 767px) {
            font-size: 24px;
          }
        }
        ul {
          padding: 0;
          li {
            font-size: 16px;
            color: #333333;
            line-height: 24px;
            margin-left: 17px;
          }
          ::marker {
            color: #0075a3;
          }
        }
      }
    }
  }
}

.review-carousel {
  h2 {
    font-size: 36px;
    color: #0075a3;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 22px;
    }
  }
  .slider-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .inner-container {
      width: 770px;
      @media (max-width: 981px) {
        width: 100% !important;
      }
      .left-quotes-container {
        position: absolute;
        z-index: 1;
        top: 34px;
        left: 145px;
        &.adjusted-left {
          left: 44px;
        }
        @media (max-width: 1054px) {
          left: 58px;
        }
        @media (max-width: 981px) {
          left: 22px;
          top: -18px;
        }
      }
      .slick-track .slick-slide {
        padding: 0 10px;
      }
      .review-slide {
        background-color: #ffffff;
        height: 276px;
        padding: 30px;
        display: flex !important;
        align-items: center !important;
        border-radius: 6px;
        margin-bottom: 30px;
        &.expanded {
          height: 407px;
        }
        @media (max-width: 650px) {
          height: 430px !important;
        }
        div {
          p {
            font-size: 18px;
            line-height: 27px;
            @media (max-width: 650px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

      .slick-arrow {
        top: 132px;
        display: block !important;
        @media (max-width: 981px) {
          display: none !important;
        }
      }

      .slick-prev {
        left: -65px;
      }
      .slick-next {
        right: -50px;
      }
      .slick-prev:before,
      .slick-next:before {
        color: #bcbcbc;
        font-size: 36px;
      }
    
      .dot__bar {
        display: inline-block;
        vertical-align: middle;
        margin: auto;
        padding: 0;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 40px;
        width: 100%;
        text-align: center;
        @media (max-width: 767px) {
          text-align: left;
        }
      }
      .dot__bar li {
        list-style: none;
        cursor: pointer;
        display: inline-block;
        margin: 0 3px;
        padding: 0;
        button {
          border: none;
          background: #c8c9c7;
          color: transparent;
          cursor: pointer;
          display: block;
          height: 15px;
          width: 15px;
          border-radius: 7.5px;
        }
      }

      .dot__bar li.slick-active button {
        background-color: #007dba;
      }
      .dot__bar li button:hover,
      .dot__bar li button:focus {
        background: #007dba;
        outline: 0;
      }
      .right-quotes-container {
        position: absolute;
        z-index: 1;
        bottom: 77px;
        right: 145px;
        &.adjusted-right {
          right: 44px;
        }
        @media (max-width: 1054px) {
          right: 58px;
        }
        @media (max-width: 981px) {
          right: 22px;
          bottom: 35px;
        }
      }
    }
  }
}

.disclaimer-section {
  margin-top: 73px;
  margin-bottom: 64px;
  @media (max-width: 540px) {
    margin-top: 53px;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 30px;
    :last-child {
      margin-bottom: 0;
    }
    @media (max-width: 540px) {
      font-size: 14px;
      color: #444444;
      line-height: 21px;
    }
    a {
      color: #0075a3;
      text-decoration: underline;
    }
  }
}

.internet-gift-card-section {
  font-size: 13px;
  color: #444444;
  font-weight: 400;
  padding-top: 13px;
  span {
    background-color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 2px;
  }
  @media (max-width: 768px) {
    padding-top:0px;
  }
  @media (min-width: 1024px) {
    display: none;
  }
}