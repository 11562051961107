.outer-thanks {
    max-width: 770px;
    margin: 0 auto;

    .main-thanks {
        padding: 30px 40px;
        border-radius: 8px;
        background-color: #fff;

        h5 {
            color: #000000;
            margin: 0px !important;
            font-weight: 600;
            font-size: 16px;
        }

        p {
            font-size: 16px;
        }

        .order-daetil-sec {
            h4 {
                text-align: center;
                font-size: 20px;
                color: #444444;
                font-weight: 800;
            }

            p {
                font-size: 16px;

                a {
                    color: #007DBA;
                    font-weight: 500;
                    text-decoration: underline;
                }
            }
        }

        .account-detail {
            margin-top: 30px;

            .f-detail {
                padding: 20px 0px;
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: repeat(2, 1fr);
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 30px;
            }

            .s-detail {
                margin-bottom: 20px;
            }


        }

        .charges-detail {
            .charges_dv {
                background-color: transparent;
                padding: 0;
                padding-bottom: 20px;
                margin-bottom: 15px;
                border-bottom: 1px solid #D3D3D3;

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }

            .p-relative {
                font-weight: 500;

                div {
                    z-index: 9;
                    background-color: #f7f7f7;
                }

                .dotted {
                    top: 12px;
                }
            }
        }

        .total-billing {
            padding-top: 20px;
            margin-top: 1.7rem;
            border-top: 1px solid #C8C9C7;

            h5 {
                font-size: 18px;
                font-weight: 800;
            }

            .line-after {
                position: relative;
                margin-left: 25px;

                &:after {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 2px;
                    background-color: black;
                    top: 0;
                    -webkit-transform: rotate(20deg);
                    -ms-transform: rotate(20deg);
                    transform: rotate(20deg);
                    left: -17px;
                }
            }
        }

        .mobile-info {
            margin: 0 auto;
            max-width: 770px;
            padding: 15px 0;
        }
    }
}