.same-protect {
    padding: 20px;

    ul {
        list-style-type: none;
        padding: 0px 20px 0px 25px;

        li {
            position: relative;
            font-size: 16px;
            margin-bottom: 8px;

            i {
                color: #007dba;
                position: absolute;
                left: -27px;
                top: 3px;
                font-size: 18px;
            }
        }
    }

    h2 {
        color: #007dba;
        font-size: 28px;
        font-weight: 600;
        margin: 0px;

    }

    h3 {
        font-size: 28px;
        margin: 15px 0px 20px;
        font-weight: 600;
    }

    h4 {
        font-size: 22px;
        color: #000;
        font-weight: 600;
        margin-bottom: 5px;
    }

    h5 {
        font-size: 18px;
        font-weight: 600;
        margin-top: 15px;
    }

    p {
        font-size: 16px;
    }

    .protect-contact {
        a {
            display: inline-block;

            i {
                margin-right: 10px;
            }
        }

        span {
            color: #007dba;
            margin-left: 15px;
            font-size: 16px;
            font-weight: 600;
        }

        h4 {
            font-size: 17px;
            font-weight: 600;
            margin-top: 8px;
            line-height: 24px;
        }
    }

    .bckup-plans {

        h2 {
            color: #f68d1f;
            font-size: 42px;
        }

        h6 {
            font-size: 18px;
            font-weight: 600;

        }
    }
}