footer {
    background-color: #57585c;
    color: #fff;
    font-size: 16px;
    padding: 30px 0px;
    background-image: url(../images/footer-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 35px;
    margin-top: 2rem;

    a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        text-decoration: underline;
    }
}

#root .without-fixed-footer {
   
    margin-bottom: 0px !important;
    display: block !important;
}