.serviceabilityCheck {
    min-height: calc(100vh - 117px);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 770px;
    flex-wrap: wrap;
    h2 {
        color: #007cba;
        font-weight: 600;
    }

    .card {
        // max-width: 770px;
        // margin: 0 auto;
        // min-height: calc(100vh - 197px);
        // display: -webkit-box;
        // display: -ms-flexbox;
        // display: flex;
        // -webkit-box-align: center;
        // -ms-flex-align: center;
        // align-items: center;
        width: 100%;


        .ant-select {
            width: 100%;

            .ant-select-selector {
                height: 50px;
                border: 1px solid #c8c9c7;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                border-bottom-width: 4px;
                position: relative;

                &:before {
                    position: absolute;
                    content: '\f3c5';
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    top: 7px;
                    left: 9px;
                    font-size: 20px;
                }

                input {
                    padding-left: 20px !important;
                }

                .ant-select-selection-item {
                    padding-top: 5px;
                    padding-left: 20px;
                    display: inline-block;
                }

                .ant-select-selection-search {
                    top: 5px;
                }
            }
        }

        .blue-button {
            width: 100%;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 50px;

            .btn-color-darkblue {
                font-size: 16px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                i {
                    margin-left: 20px;
                    font-size: 18px;
                }
            }
        }

        .inner-card {
            background-color: #fff;
            padding: 20px;
            border: 1px solid #c8c9c7;
            display: inline-block;
            width: 100%;
            border-radius: 5px;
            border-bottom-width: 4px;
        }
    }

    .addres-labels {
        .d-flex {
            margin: 0px 0px 5px;

            i {
                font-size: 11px;
                margin-right: 10px;
                height: 22px;
                width: 25px;
                background-color: #007cba;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                border-radius: 50%;
                color: #fff;
            }
        }

        .ant-checkbox-wrapper {
            width: 100%;

        }
    }

    .disclaimer-text {
        margin-top: 1rem;
        font-weight: 600;
    }
}

.hidden-label {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}