.outer-header {
    padding: 15px 0px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 99;
    border-bottom: 1px solid #d9d9d6;
    top: 0;
}

header {
    .header-cart {
        i {
            color: #f68d1f;
            font-size: 22px;
        }

        p {
            margin: 0px 10px;
            font-size: 20px;
            text-transform: capitalize;
            font-weight: 500;
        }

        span {
            height: 26px;
            width: 26px;
            background-color: #007cba;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-radius: 50px;
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            margin-left: 3px;
        }
    }
    .header-tfn {
        margin-right: 20px;
        i {
            color: #f68d1f;
            font-size: 25px;
            transform: rotate(-15deg);
            margin-right: 7px;
        }
        a {
            display: flex;
            align-items: center;
            font-size: 21px;
            margin-left: 4px;
            font-weight: 700;
            color: #007cba;
        }
    }
}