.create-account .loader-out {
  margin-top: 2.3rem;
  margin-left: 25px;
  height: auto;
}

.compatible-tab-checkout .loader-out {
  height: auto;
  margin-top: 15px;
  margin-left: 25px;
}

.loader-out {
  height: 77px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mian-loader span {
  background-color: #007dba;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 6px;
}

.mian-loader .first {
  -webkit-animation: animation-10yals0 0.5s 0.07s infinite ease-in-out;
  animation: animation-10yals0 0.5s 0.07s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes animation-10yals0 {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@keyframes animation-10yals0 {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

.mian-loader .second {
  -webkit-animation: animation-10yals0 0.5s 0.14s infinite ease-in-out;
  animation: animation-10yals0 0.5s 0.14s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.mian-loader .third {
  -webkit-animation: animation-10yals0 0.5s 0.21000000000000002s infinite ease-in-out;
  animation: animation-10yals0 0.5s 0.21000000000000002s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}